import {
  ONBOARDING_CATEGORIES,
  ONBOARDING_TASKS,
  OnboardingCategory,
  OnboardingCategoryId,
  OnboardingTask,
  ClientOnboardingTaskId,
  WorkspaceOnboardingTaskId,
} from "~/types/Onboarding";
import { Client } from "~/types/Client";
import { Workspace } from "~/types/Workspace";

export const useOnboarding = () => {
  const minOnboardingPercentage = 10;

  const getOnboardingTasks = (): OnboardingTask[] => {
    const { hasFeature } = useFeatureFlags();
    const { isAuthorized } = useAppUser();
    const { appUserMe } = useAppUserStore();
    return ONBOARDING_TASKS.filter((task) => {
      return typeof task.featureFlag === "string"
        ? hasFeature(task.featureFlag)
        : true;
    }).filter((task) => {
      return typeof task.minRole === "string"
        ? isAuthorized(appUserMe, task.minRole)
        : true;
    });
  };

  const getOnboardingCategories = (dto: {
    client: Client;
    workspace: Workspace | null;
    showOnlyCompleted: boolean;
    showOnlyIncomplete: boolean;
  }): OnboardingCategory[] => {
    const { hasFeature } = useFeatureFlags();
    const { isAuthorized } = useAppUser();
    const { appUserMe } = useAppUserStore();
    return (
      ONBOARDING_CATEGORIES.filter((category) => {
        return typeof category.featureFlag === "string"
          ? hasFeature(category.featureFlag)
          : true;
      })
        .filter((category) => {
          return typeof category.minRole === "string"
            ? isAuthorized(appUserMe, category.minRole)
            : true;
        })
        .filter((category) => {
          const tasks = getTasksInCategory(category.id);
          return tasks.length > 0;
        })
        .filter((category) => {
          if (dto.showOnlyCompleted) {
            return isCategoryCompleted({
              client: dto.client,
              workspace: dto.workspace,
              categoryId: category.id,
            });
          } else if (dto.showOnlyIncomplete) {
            return !isCategoryCompleted({
              client: dto.client,
              workspace: dto.workspace,
              categoryId: category.id,
            });
          } else {
            return true;
          }
        })
        // Sort completed to the end
        .sort((a, b) => {
          const aCompleted = isCategoryCompleted({
            client: dto.client,
            workspace: dto.workspace,
            categoryId: a.id,
          });
          const bCompleted = isCategoryCompleted({
            client: dto.client,
            workspace: dto.workspace,
            categoryId: b.id,
          });
          if (aCompleted && !bCompleted) {
            return 1;
          } else if (!aCompleted && bCompleted) {
            return -1;
          } else {
            return 0;
          }
        })
    );
  };

  const getClientCompletedTasks = (
    client: Client,
    workspace: Workspace | null,
  ) => {
    const completedTasks: Array<
      ClientOnboardingTaskId | WorkspaceOnboardingTaskId
    > = [...client.completedTasks];
    if (workspace) {
      completedTasks.push(...workspace.completedTasks);
    }
    return getOnboardingTasks().filter((task) =>
      completedTasks.includes(task.id),
    );
  };

  const getCategory = (categoryId: OnboardingCategoryId) => {
    return ONBOARDING_CATEGORIES.find(
      (category) => category.id === categoryId,
    ) as OnboardingCategory;
  };

  const getTask = (
    taskId: ClientOnboardingTaskId | WorkspaceOnboardingTaskId,
  ) => {
    return getOnboardingTasks().find(
      (task) => task.id === taskId,
    ) as OnboardingTask;
  };

  const getTotalImportance = () => {
    return getOnboardingTasks().reduce(
      (total, task) => total + task.importance,
      0,
    );
  };

  const getTaskPercentageGained = (task: OnboardingTask) => {
    return Math.round((task.importance / getTotalImportance()) * 100);
  };

  const getCategoryPercentageGained = (categoryId: OnboardingCategoryId) => {
    const categoryImportance = getTasksInCategory(categoryId).reduce(
      (total, task) => total + task.importance,
      0,
    );
    return Math.round((categoryImportance / getTotalImportance()) * 100);
  };

  const getClientCompletionPercentage = (
    client: Client,
    workspace: Workspace | null,
  ) => {
    // Not based on number of tasks, but on importance of tasks
    const completedTasks = getClientCompletedTasks(client, workspace);
    const completedImportance = completedTasks.reduce(
      (total, task) => total + task.importance,
      0,
    );
    return Math.round((completedImportance / getTotalImportance()) * 100);
  };

  const getCategoryCompletionPercentage = (dto: {
    client: Client;
    workspace: Workspace | null;
    categoryId: OnboardingCategoryId;
  }) => {
    const tasksInCategory = getTasksInCategory(dto.categoryId);
    const clientCompletedTasks = getClientCompletedTasks(
      dto.client,
      dto.workspace,
    );
    const completedTasks = clientCompletedTasks.filter((task) =>
      tasksInCategory.includes(task),
    );
    const numCompletedTasks = completedTasks.length;
    const numTasks = tasksInCategory.length;
    return Math.round((numCompletedTasks / numTasks) * 100);
  };

  const getTasksInCategory = (categoryId: OnboardingCategoryId) => {
    return getOnboardingTasks().filter(
      (task) => task.categoryId === categoryId,
    );
  };

  const isTaskCompleted = (dto: {
    client: Client;
    workspace: Workspace | null;
    taskId: ClientOnboardingTaskId | WorkspaceOnboardingTaskId;
  }) => {
    return getClientCompletedTasks(dto.client, dto.workspace).some(
      (task) => task.id === dto.taskId,
    );
  };

  const isCategoryCompleted = (dto: {
    client: Client;
    workspace: Workspace | null;
    categoryId: OnboardingCategoryId;
  }) => {
    const tasksInCategory = getTasksInCategory(dto.categoryId);
    const completedTasks = getClientCompletedTasks(dto.client, dto.workspace);
    return tasksInCategory.every((task) => completedTasks.includes(task));
  };

  const isClientOnboardingComplete = (
    client: Client,
    workspace: Workspace | null,
  ) => {
    const completedTasks = getClientCompletedTasks(client, workspace);
    return getOnboardingTasks().every((task) => completedTasks.includes(task));
  };

  const getColor = (width: number) => {
    if (width < 33) {
      return {
        bg: "bg-red-50",
        hoverBg: "hover:bg-red-50",
        progressBg: "bg-red-500",
        text: "text-red-500",
        ring: "ring-red-500",
      };
    } else if (width < 66) {
      return {
        bg: "bg-yellow-50",
        hoverBg: "hover:bg-yellow-50",
        progressBg: "bg-yellow-500",
        text: "text-yellow-500",
        ring: "ring-yellow-500",
      };
    } else {
      return {
        bg: "bg-green-50",
        hoverBg: "hover:bg-green-50",
        progressBg: "bg-green-500",
        text: "text-green-500",
        ring: "ring-green-500",
      };
    }
  };

  const isClientOnboardingTask = (
    taskId: string,
  ): taskId is ClientOnboardingTaskId => {
    return Object.values(ClientOnboardingTaskId).includes(taskId as any);
  };

  const isWorkspaceOnboardingTask = (
    taskId: string,
  ): taskId is WorkspaceOnboardingTaskId => {
    return Object.values(WorkspaceOnboardingTaskId).includes(taskId as any);
  };

  const onBookOnboarding = async (e: MessageEvent<any>): Promise<boolean> => {
    const { appUserMe } = useAppUserStore();
    const { getActiveWorkspace } = useWorkspace();
    const activeWorkspace = getActiveWorkspace(appUserMe);
    if (
      isCalendlyEvent(e) &&
      e.data.event === "calendly.event_scheduled" &&
      activeWorkspace
    ) {
      const { notify } = useNotifications();
      const workspaceStore = useWorkspaceStore();
      const errorMaybe = await workspaceStore.updateWorkspace({
        workspaceId: activeWorkspace.id,
        bookedOnboarding: true,
      });
      if (typeof errorMaybe === "string") {
        notify(errorMaybe, "");
        return false;
      }
      return true;
    }
    return false;
  };

  const isCalendlyEvent = (e: MessageEvent<any>) => {
    return (
      e.origin === "https://calendly.com" &&
      e.data.event &&
      e.data.event.indexOf("calendly.") === 0
    );
  };

  return {
    getClientCompletionPercentage,
    getClientCompletedTasks,
    getColor,
    getCategory,
    getTask,
    getOnboardingTasks,
    getTasksInCategory,
    isTaskCompleted,
    isCategoryCompleted,
    minOnboardingPercentage,
    getTaskPercentageGained,
    getCategoryPercentageGained,
    isClientOnboardingComplete,
    getCategoryCompletionPercentage,
    getOnboardingCategories,
    isClientOnboardingTask,
    isWorkspaceOnboardingTask,
    onBookOnboarding,
  };
};
